body, html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0; 
  box-sizing: border-box;
}







.App {
  display: grid;
  text-align: center;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  grid-template-columns: 1fr minmax(300px , 600px) 1fr;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas: 'header header header' '. searchAndKnot .' '. knot-status .' '. knots .';
}




.knot-status {
  grid-area: knot-status;
}

.search-bar-container {
  grid-area: searchAndKnot;

}

.knot-container-box {
  grid-area: knots;
}

.settings-container {
  grid-area: knots ;

}


.inline-text {
  display: inline;
}

#selectNav{
  margin-left: 6px;
  margin-right: 6px;
}

.inline-sel{
  display: inline;
  border-radius: 15px;
  border: 1px solid white;
  background-color:#FF5050 ;
  color: white;
	-moz-appearance: none; 
	-webkit-appearance: none; 
  font-size: 16px;
	appearance: none;
	padding: 6px 20px 6px 8px ;
  text-align: left;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA0LjkgMTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQuOSAxMDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I2ZmZmZmZjt9DQo8L3N0eWxlPg0KPHRpdGxlPmFycm93czwvdGl0bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjEuNCw0LjcgMi41LDMuMiAzLjUsNC43ICIvPg0KPHBvbHlnb24gY2xhc3M9InN0MCIgcG9pbnRzPSIzLjUsNS4zIDIuNSw2LjggMS40LDUuMyAiLz4NCjwvc3ZnPg==) no-repeat 95% 50%; 

}
.parag{
  color: red;
  font-weight: 550;
}

header {
  background-color: #FF5050;
  height: auto;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  grid-area: header;
  text-align: center;

}




#top-h1 {
margin: 0;
margin-bottom: 10px;
}

.flex-container{
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  margin-top: 10px;
  padding: 5px;
}

.sort-button {
  font-size: 18px;
  border-radius:20px;
  border: none;
  padding: 5px;
  flex-basis: 200px;
  border: 1px solid black;
}

.sort-button:active {
  border:  1px solid red; 
}


#searchBar {
  margin-top: 10px;
  width: 95%;
  margin-left: 5px;
  margin-bottom: 5px;
  border-radius: 20px;
  height: 40px;
  border: 1px solid black;
  font-size: 15px;
  margin-bottom: 10px;
  text-align: center;
}


.settings-card {
  border-radius: 20px;
  margin: 10px auto;
  background-color: #E7E5DF;
  text-align: left;
  width: 95%;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  height: auto;
}




.knot-card {
  border-radius: 20px;
  margin: 10px auto;
  background-color: #E7E5DF;
  padding: 10px; 
  margin: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: auto;
}




.grid-knot {
  background-color: #E7E5DF;
  border-radius: 20px;
  padding: 10px; 
  margin: 10px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 
      "taken-button knot-info bookmark"  
      "expandedkNO expandedkNO expandedkNO"; 
  gap: 15px;

  text-align: left; 
  align-items: center; 
}; 


.bold {
  font-weight:700; 
}


.knot-expanded{
  grid-area: expandedkNO;
}



.note-text-area{
  width: 95%;
  background-color: #D9D9D9;
  border-radius: 10px;
  margin-bottom: 10px;
  resize: none;
  padding: 10px;
  font-size: 1rem;

}

.taken-button {
  grid-area: taken-button;
}

.knot-info {
  grid-area:knot-info ;
}

.bookmark{
  grid-area: bookmark;
}



.settings-container {
  grid-area: knots;
}


.headline  {
    margin-top:16px !important ;
    margin-bottom: 5px;
    font-weight: 700;
}


.settings-paragrap{
  margin-top: 0;
  margin-bottom: 0px;
}


.knot-card p {
  padding-left:10px ;
  margin: 0;
  margin-bottom: 5px;
  padding-right: 10px;
}



.flx {
  display: flex;
  align-items: center;
  padding-right: 10px;
  align-self: stretch;
}

.flx1 {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 5px;
  padding-top: 5px;
  gap: 5px;
}

.knot-name-headline {
  padding-top: 10px;
  font-weight: 700;
}

.knot-description-paragraph{
  padding-bottom: 0px;
}

.play-Ball-button {
  height: 35px;
  width: 35px;
  background-image: url('../public/checkmark.svg');
  margin-left: 10px;
  border: none;
  background-color: #E7E5DF;
  flex-shrink: 0;
}

.play-Ball-button:hover {
  cursor: pointer; 
}

.card-text {
  margin-left:5px;
}


.taken-selected {
  background-image: url('../public/checkmark_taken.svg');
}

.vl {
  border-left: 4px solid #FF5050;
  padding-left: 5px;
  padding-right: 10px ;
  align-self: stretch;
}

.icon {
  background-color:  #E7E5DF;
  background-image: url('../public/bookmark_add.svg');
  height: 37px;
  width: 28px;
  border: none;
  flex-shrink: 0;
}

.icon:hover {
  cursor: pointer;
}


.selected {
  background-image: url('../public/bookmark_remove.svg');
}



.marked {
  background-color: #FF5050;
  color: white;
  border-radius: 5px;
  padding-left: 8px;
  width: 75px;
  padding-right: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  font-weight: normal;
  margin-left: 5px;
  margin-right: 8px;
  display: inline-block;
  margin-top: 3px;
}



.knot-non-found-card {
  border-radius: 20px;
  background-color: #E7E5DF;
  margin: 10px auto;
  flex-direction: column;
  width: 40vw;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}


.no-hits-emoji {
  font-size: 40px;
  margin-left: 10px;
  flex-shrink: 0;
  padding-right: 0 !important;
  margin-bottom: 0 !important;
  padding-top:5px ;
  padding-bottom: 5px;
}
 
.marked-taken {
  background-color: lightseagreen;
  color: white;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-weight: normal;
}

.note-button{
  border-radius: 6px;
  padding-left: 15px;
  padding-right: 15px;
  border: white;
  background-color: lightblue;
  margin-left: 5px;
}

.note-button:hover {
  cursor: pointer;
  box-shadow: 5px 10px 26px -6px rgba(0,0,0,0.62);
  -webkit-box-shadow: 5px 10px 26px -6px rgba(0,0,0,0.62);
  -moz-box-shadow: 5px 10px 26px -6px rgba(0,0,0,0.62);
}


.settings-emoji {
  font-size: 40px;
  margin-left: 13px !important;
  flex-shrink: 1;
  padding-right: 0 !important;
  margin-bottom: 0 !important;
  padding-top:5px ;
  padding-bottom: 5px;
}

.settings-link {
  background-color: lightblue;
  padding: 10px;
  border-radius: 6px;
  color: white;
  text-decoration: none;
  margin-bottom: 16px;
}

.settings-link:hover {
  box-shadow: 5px 10px 26px -6px rgba(0,0,0,0.62);
  -webkit-box-shadow: 5px 10px 26px -6px rgba(0,0,0,0.62);
  -moz-box-shadow: 5px 10px 26px -6px rgba(0,0,0,0.62);
}





.settings-flex {
  display: flex; 
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-right: 10px;
}




 .settings-gear {
  flex-grow: 0;
  flex-shrink: 1;
  text-decoration: none;
  font-size: 16px;
  margin-right: 16px;
  background-color: lightslategray;
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  color: white;
  padding-bottom: 10px;
  padding-top: 10px;

}








.back-stretch {
  flex-grow: 1;
  margin-top: 16px;
}


.taken-card-text {
  flex-grow: 1;
}


.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}






select:disabled {
  color: lightgrey;
}


 /* CSS */
 .button-19 {
  appearance: button;
  background-color: #118AB2;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: din-round,sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 16px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.btn-green {
  background-color:#00E685 ;
}

.button-19:main,
.button-19:focus {
  user-select: auto;
}

.button-19:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}

.button-19:disabled {
  cursor: auto;
}


/* CSS */
.button-new {
  border: 2px solid #FF5050;
  background-color: #E7E5DF ;
  cursor: pointer;
  color: black;
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter .2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  position: relative; 

}


.button-new:main,
.button-new:focus {
  user-select: auto;
}

.button-new:hover:not(:disabled) {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
}


.circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #00E685 ;
  position: absolute;
  top: -5px;
}